import React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import "../styles/global.scss"

const IndexPage = () => (
  <Layout title="Contact Us">
    <SEO title="Contact Us" />
    <section>
      <div className="container">
        <div className="columns">
          <div className="column is-two-thirds">
            <h3 className="title">Email Us</h3>
            <p>To send us an email, fill out the form below. We will get back to you as soon as possible.</p>
            <form 
              style={{ marginTop: `2em`}}
              data-netlify="true" 
              netlify="netlify"
              method="POST" 
              name="Contact" 
              action="/success">
              <div className="field">
                <label htmlFor="firstname" className="label">First Name</label>
                <div className="control">
                  <input id="firstname" name="firstname" className="input" type="text" placeholder="First name"/>
                </div>
              </div>
              <div className="field">
                <label htmlFor="lastname" className="label">Last Name</label>
                <div className="control">
                  <input id="lastname" name="lastname" className="input" type="text" placeholder="Last name"/>
                </div>
              </div>

              <div className="field">
                <label htmlFor="phone" className="label">Phone</label>
                <div className="control">
                  <input id="phone" name="phone" className="input" type="phone" placeholder="Phone number"/>
                </div>
              </div>

              <div className="field">
                <label htmlFor="email" className="label">Email</label>
                {/* <div className="control has-icons-left has-icons-right"> */}
                <div className="control">
                  <input id="email" name="email" className="input" type="email" placeholder="Email"/>
                  {/* <span className="icon is-small is-left">
                    <i className="fas fa-envelope"></i>
                  </span> */}
                  {/* <span className="icon is-small is-right">
                    <i className="fas fa-exclamation-triangle"></i>
                  </span> */}
                </div>
                {/* <p className="help is-danger">This email is invalid</p> */}
              </div>

              <div className="field">
                <label htmlFor="preferred" className="label">Preferred contact method</label>
                <div className="control">
                  <div className="select">
                    <select id="preferred" name="method">
                      <option>Phone</option>
                      <option>Email</option>
                    </select>
                  </div>
                </div>
              </div>

              <div className="field">
                <label htmlFor="message" className="label">Message</label>
                <div className="control">
                  <textarea id="message" name="message" className="textarea" placeholder="What would you like to say?"></textarea>
                </div>
              </div>

              <input type="hidden" name="form-name" value="Contact" />

              {/* <div className="field">
                <div className="control">
                  <label className="checkbox">
                    <input type="checkbox"/>
                    <span style={{ marginLeft: `0.5em` }}>I agree to the <a href="#">terms and conditions</a></span>
                  </label>
                </div>
              </div> */}

              <div className="field is-grouped">
                <div className="control">
                  <button className="button is-link">Submit</button>
                </div>
              </div>
            </form>
          </div>
        </div>
        <div className="columns" style={{ 
             marginTop: `2em`, 
             borderTop: `1px solid #d1d1d1`,
             paddingTop: `2em`}}>
                <div className="column is-two-thirds">
                <h3 className="title">Visit us</h3>
                <p>9512 Juanita St.,<br/> Cypress, CA 90630</p>
                </div>
        </div>
        <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d1060.6126029976551!2d-118.02403200233768!3d33.8239433766893!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x80dd2eb2105b1c8f%3A0x89e71e0d570a4698!2s9512%20Juanita%20St%2C%20Cypress%2C%20CA%2090630!5e1!3m2!1sen!2sus!4v1605289251516!5m2!1sen!2sus" 
                width="450px"
                height="450px"
                id="myId"
                className="myClassname"
                display="initial"
                width="450" 
                height="450" 
                frameborder="0"
                allow="fullscreen">
          </iframe>        
        </div>
    </section>
  </Layout>
)

export default IndexPage